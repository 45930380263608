@font-face {
  font-family: "BasierCircle";
  src: url("./assets/fonts/BasierCircleRegular.otf");
  font-weight: 400;
  font-style: "normal";
  font-display: "swap";
}

@font-face {
  font-family: "BasierCircle";
  font-style: "normal";
  font-display: "swap";
  src: url("./assets/fonts/BasierCircleMedium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "BasierCircle";
  font-style: "normal";
  font-display: "swap";
  src: url("./assets/fonts/BasierCircleBold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "Basier Regular";
  src: url("./assets/fonts/BasierCircleRegular.otf");
}

@font-face {
  font-family: "Basier Medium";
  src: url("./assets/fonts/BasierCircleMedium.otf");
}

@font-face {
  font-family: "Basier Bold";
  src: url("./assets/fonts/BasierCircleBold.otf");
}

/* html, body, #app, #app> div {
  height: 100%;
  width: 100%;
} */

/* Force calendar styling */
.MuiPickersCalendarHeader-switchHeader > div p {
  color: #151d15;
  font-family: "Basier Medium";
}

/* Force cursor styling to the upload button */
input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

/* Change Autofill styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff;
  font-size: 17;
  font-family: "Basier Regular";
}
